import {ApplicationConfig, importProvidersFrom, provideExperimentalZonelessChangeDetection, provideZoneChangeDetection} from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { USE_LOG_NINJA } from '@services/log-ninja.service';
import { environment } from '../environments/environment';
import { UIEditViewTheme, UIListViewTheme} from '@buildable/foundation';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CanActivateModule } from "@app/app.routing.can-activate";
import { provideAnimations } from "@angular/platform-browser/animations";


// configure the UI app theme
// DEFAULT
// listview
UIListViewTheme.default.tableClass = 'table-striped'; // set the default listview to use Bootstrap table stripes
UIListViewTheme.default.showCheckboxIcon = true; // show icons instead of checkboxes
UIListViewTheme.default.showViewButton = true; // hide the view button column for all lists
UIListViewTheme.default.useNewReportFilter = false; // use the new version of the search filter
UIListViewTheme.default.disableOwlDatetimePickerIcon = true; // disable the datepicker calendar icon because it's buggy
UIListViewTheme.default.enableInlineEditButtons = true; // show inline edit pens as buttons
UIListViewTheme.default.editPenButtonClass = "btn-link";
// editview
UIEditViewTheme.default.saveButtonIcon = "check"; // show checkmark icon next to save button text
UIEditViewTheme.default.showCheckboxIcon = true; // show icons instead of checkboxes

// COMPACT
// listview
UIListViewTheme.compact.disableOwlDatetimePickerIcon = true; // disable the datepicker calendar icon because it's buggy
UIListViewTheme.compact.useNewReportFilter = false; // use the new version of the search filter

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptorsFromDi()),
    // a partial hack to get the scroll position to be restored to the top of the page
    // important for the client facing landing pages, as otherwise they'd be half way down the page
    // this has a negative impact on the admin facing pages, but that's a problem for future us
    provideRouter(routes, withInMemoryScrolling({
      scrollPositionRestoration: 'top',
    })),
    provideAnimations(),
    { provide: USE_LOG_NINJA, useValue: environment.useLogNinja },
    // Import provider for CanActivateModule
    importProvidersFrom(CanActivateModule),
  ],
};
