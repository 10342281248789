<div id="nav-loading-bar-container">
  <!--  <ui-loading-bar
    [show]="(loadingService.showLoading$ | async) ?? false"
    [backgroundClass]="'load-bar-bg'"
    [foregroundClass]="'load-bar-fg'"
  ></ui-loading-bar>-->
</div>

<!--
  Add [ngClass]="{'navbar-scrolled': scrolled}" to toggle the shadow class
  based on the boolean "scrolled" variable in the .ts file
-->
<nav
  class="navbar bg-white navbar-expand-md fixed-top"
  [ngClass]="{'navbar-scrolled': scrolled}"
>
  <div class="container-fluid">
    <a class="navbar-brand  ml-lg-2" [routerLink]="['/home']">
      <img
        class="navbar-brand-img"
        src="/images/homewrite_logos_notagline_main_horiz.png"
        style="height: 48px; width: auto"
        title="Homewrite Platform"
      />
    </a>

    <button
      id="navbar-toggler"
      class="navbar-toggler custom-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <fa-icon [size]="'lg'" [icon]="faBars"></fa-icon>
    </button>

    <div class="collapse navbar-collapse " id="navbarSupportedContent">
      <ul class="navbar-nav d-flex justify-content-end align-items-center ">
        <!--  public    -->
        <ul *ngIf="!loggedIn" class="mr-0 mr-sm-3 pl-0">
          <li class="nav-item nav-options" [routerLinkActive]="['active']">
            <a [routerLink]="['/learn-more']" class="nav-link" (click)="closeMobileMenu($event)">Learn More</a>
          </li>
          <li class="nav-item nav-options" [routerLinkActive]="['active']">
            <a [routerLink]="['/rewards']" class="nav-link" (click)="closeMobileMenu($event)">Homewrite Rewards</a>
          </li>
          <li class="nav-item nav-options" [routerLinkActive]="['active']">
            <a [routerLink]="['/listing-services']" class="nav-link" (click)="closeMobileMenu($event)">Listing Services</a>
          </li>
          <li class="nav-item nav-options" [routerLinkActive]="['active']">
            <a [routerLink]="['/about-us']" class="nav-link" (click)="closeMobileMenu($event)">About Us</a>
          </li>
          <!-- commented out because I'm not sure what the point of having a book call + talk to agent that does the same thing -->
          <!-- <li class="nav-item nav-options nav-item-last" [routerLinkActive]="['active']">
            <a
              href="https://calendly.com/d/cmw5-djn-6n2/buyer-consultation"
              class="nav-link"
              target="_blank"
              (click)="closeMobileMenu($event)"
            >Book a Call</a>
          </li> -->
        </ul>

        <!-- admin menu -->
        <ng-container
          *ngIf="
            loggedIn &&
            userIdentityCurrent &&
            userIdentityCurrent.identityType == 'admin'
          "
        >
          <li class="nav-item nav-options" [routerLinkActive]="['active']">
            <a
              [routerLink]="['/admin/mls-data']"
              class="nav-link"
              (click)="closeMobileMenu($event)"
            >
              MLS Data
            </a>
          </li>

          <li class="nav-item nav-options dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownSystem"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Admin
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownSystem">
              <a
                [routerLink]="['/system/users']"
                class="dropdown-item"
                (click)="closeMobileMenu($event)"
                >User Management</a
              >
              <a
                [routerLink]="['/system/admins']"
                class="dropdown-item"
                (click)="closeMobileMenu($event)"
                >Team Management</a
              >
              <a
                [routerLink]="['/system/agents']"
                class="dropdown-item"
                (click)="closeMobileMenu($event)"
                >Agents Management</a
              >
              <a
                [routerLink]="['/system/roles']"
                class="dropdown-item"
                (click)="closeMobileMenu($event)"
                >Roles &amp; Permissions</a
              >
              <!-- TODO -->
              <a
                [routerLink]="['/system/activity-log']"
                (click)="closeMobileMenu($event)"
                class="dropdown-item disabled"
                disabled
                >Activity Log</a
              >
              <!-- TODO -->
              <a
                [routerLink]="['/system/email-log']"
                (click)="closeMobileMenu($event)"
                class="dropdown-item disabled"
                disabled
                >Email Log</a
              >
              <!-- TODO: dev-only -->
              <!-- <div class="dropdown-divider"></div>
              <a
                [routerLink]="['/system/design']"
                class="dropdown-item"
                (click)="closeMobileMenu($event)"
           nav-options      >Design System</a> -->
            </div>
          </li>
        </ng-container>

        <ng-container
          *ngIf="
            loggedIn &&
            userIdentityCurrent &&
            (userIdentityCurrent.identityType == 'buyer'
              || userIdentityCurrent.identityType == 'admin')
          "
        >
          <li class="nav-item nav-options" [routerLinkActive]="['active']">
            <a
              [routerLink]="['/buyer']"
              class="nav-link"
              (click)="closeMobileMenu($event)"
              >Start Offer</a
            >
          </li>
        </ng-container>

        <!--  public    -->
        <!--      <li class="nav-item" [routerLinkActive]="['active']">
                  <a [routerLink]="['/learn-more']" class="nav-link" (click)="closeMobileMenu($event)">Learn More</a>
                </li>-->

        <ng-container
          *ngIf="
            loggedIn &&
            userIdentityCurrent &&
            (userIdentityCurrent.identityType == 'agent'
              || userIdentityCurrent.identityType == 'admin')
          "
        >
          <li class="nav-item nav-options" [routerLinkActive]="['active']">
            <a
              [routerLink]="['/agent/offers']"
              class="nav-link"
              (click)="closeMobileMenu($event)"
              >Offers</a
            >
          </li>
        </ng-container>

        <ng-container
          *ngIf="
            loggedIn && userIdentityCurrent && auth.hasRolePortalAccess()
          "
        >
          <li class="nav-item" [routerLinkActive]="['active']">
            <a
              (click)="closeMobileMenu($event); auth.logout()"
              class="nav-link d-flex align-items-center"
            >
              <span class="mr-2">Logout</span>
              <fa-icon [icon]="['fas', 'right-from-bracket']"></fa-icon>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="!loggedIn">
          <li class="nav-item" [routerLinkActive]="['active']">
            <a
              [routerLink]="['/login']"
              class="btn btn-primary btn-sm mb-2 mb-md-0 mr-0 mr-md-2 px-3"
              (click)="closeMobileMenu($event)"
            >
              <span>Sign in</span>
            </a>
          </li>
        </ng-container>

        <li>
          <a href="https://calendly.com/d/cmw5-djn-6n2/buyer-consultation" target="_blank" class="btn btn-tertiary btn-sm px-3 mb-2 mb-md-0">
            <fa-icon [icon]="['fas', 'phone']"></fa-icon>
            Talk with an Agent
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
