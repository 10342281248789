import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@app/icons/icons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FooterComponent } from '@components/footer/footer.component';

@Component({
  selector: 'app-learn-more',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    IconsModule,
    RouterModule,
    FooterComponent
  ],
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss']
})
export class LearnMoreComponent {
  public readonly XMARK = faXmark;

  constructor() { }
}
