import { Routes } from '@angular/router';
import {CheckAdminView, CheckAgentView, CheckPortalAccess} from './app.routing.can-activate';
import { ResetComponent } from '@containers/home/reset.component';
import { LoginComponent } from '@containers/home/login.component';
import { NotAuthorizedComponent } from '@containers/home/not-authorized.component';
import { NotFoundComponent } from '@containers/home/not-found.component';
import { ResetPasswordComponent } from '@containers/user-profile/reset-password.component';
import { UserRegistrationComponent } from '@containers/user-profile/user-registration.component';
import { ChangePasswordComponent } from '@containers/user-profile/change-password.component';
import { SystemLogMasterComponent } from '@containers/system/system-log-master.component';
import { SystemUserMasterComponent } from '@containers/system/system-user-master.component';
import { SystemUserDetailComponent } from '@containers/system/system-user-detail.component';
import { SystemRoleItemListComponent } from '@containers/system/system-role-item-list.component';
import { SystemAdminListComponent } from '@containers/system/system-admin-list.component';
import { DesignSystemComponent } from '@containers/system/design-system.component';
import { AdminHomeComponent } from '@containers/admin/admin-home.component';
import { CsvImportComponent } from "@containers/admin/csv-import.component";
import { OfferFormComponent } from "@containers/buyer/offer-form/offer-form.component";
import { OfferFormDetailComponent } from "@containers/buyer/offer-detail/offer-form-detail.component";
import { BuyerRegisterComponent } from "@containers/home/buyer-register/buyer-register.component";
import { SystemAgentListComponent } from "@containers/system/system-agent-list.component";
import { PreApprovalUploadComponent } from "@containers/buyer/pre-approval-upload/pre-approval-upload.component";
import { OfferWizardComponent } from "@containers/buyer/offer-wizard/offer-wizard.component";
import { OfferStatusComponent } from "@containers/buyer/offer-status/offer-status.component";
import { OffersListComponent } from "@containers/agent/offers-list.component";
import { OfferDetailComponent } from "@containers/agent/offer-detail/offer-detail.component";
import { HomeComponent } from "@containers/home/home.component";
import { BuyerHomeComponent } from "@containers/buyer/buyer-home.component";
import { LearnMoreComponent } from "@containers/home/learn-more.component";
import { RewardsComponent } from "@containers/home/rewards.component";
import { AboutUsComponent } from "@containers/home/about-us.component";
import { TermsAndConditionsComponent } from "@containers/home/terms-and-conditions.component";
import { ListingServicesComponent } from "@containers/home/listing-services.component";

export const routes: Routes = [
	/* PUBLIC */
	{ path: '', redirectTo: 'home/', pathMatch: 'full' },
	{ path: 'home', redirectTo: 'home/' },
  { path: 'home/:hash', component: HomeComponent, data: { title: 'Home' } },
  { path: 'rewards', component: RewardsComponent, data: { title: 'Rewards' } },
  { path: 'about-us', component: AboutUsComponent, data: { title: 'About Us' } },
  { path: 'learn-more', component: LearnMoreComponent, data: { title: 'Learn More' } },
  { path: 'listing-services', component: ListingServicesComponent, data: { title: 'Listing Services' } },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: { title: 'Terms and Conditions' } },

	{ path: 'not-authorized', component: NotAuthorizedComponent, data: { title: 'Not Authorized' } },
	{ path: 'not-found', component: NotFoundComponent, data: { title: 'Not Found' } },
	{ path: 'register', component: BuyerRegisterComponent, data: { title: 'Sign Up' } },
  { path: 'login', component: LoginComponent, data: { title: 'Log in' } },
	{ path: 'reset', component: ResetComponent, data: { title: 'Reset Password' } },

	/* PRIVATE */
	/* ADMIN */
	{
	  path: 'admin',
	  children: [
			/* admin/ */
			{ path: 'home', component: AdminHomeComponent, canActivate: [CheckAdminView], data: { title: 'Home' } },
			{ path: 'mls-data', component: CsvImportComponent, canActivate: [CheckAdminView], data: { title: 'MLS Data Import' } },
	  ],
	  canActivate: [CheckAdminView]
	},

  /* BUYER */
	{
	  path: 'buyer', component: BuyerHomeComponent, data: { title: 'Buyer' },
    children: [
			/* buyer/ */
			{ path: 'offer-detail', component: OfferFormComponent, canActivate: [CheckPortalAccess], data: { title: 'Buyer Offer' } },
			{ path: 'offer-detail/:id', component: OfferFormDetailComponent, canActivate: [CheckPortalAccess], data: { title: 'Buyer Offer Detail' } },
			{ path: 'pre-approval-upload', component: PreApprovalUploadComponent, canActivate: [CheckPortalAccess], data: { title: 'Pre-approval Upload' } },
			{ path: 'offer-wizard', component: OfferWizardComponent, canActivate: [CheckPortalAccess], data: { title: 'Start Offer' } },
			{ path: 'offer', component: OfferStatusComponent, canActivate: [CheckPortalAccess], data: { title: 'Offer Status' } },
	  ],
	  canActivate: [CheckPortalAccess]
	},

  /* AGENT */
	{
	  path: 'agent',
	  children: [
			/* buyer/ */
			{ path: 'offers', component: OffersListComponent, canActivate: [CheckAgentView], data: { title: 'Offers List' } },
			{ path: 'offers/:id', component: OfferDetailComponent, canActivate: [CheckAgentView], data: { title: 'Offers List' } },
	  ],
	  canActivate: [CheckPortalAccess]
	},

	/* user/xxx/xxx/ => lazy loaded, but allow all, so reset password can work too */
	{
	  path: 'user',
	  children: [
			/* public: user/ */
			{ path: 'create-password/:hash', component: ResetPasswordComponent, data: { reset: false, title: 'Create Password' },  },
			{ path: 'reset-password/:hash', component: ResetPasswordComponent, data: { reset: true, title: 'Reset Password' } },
			{ path: 'complete-registration/:hash', component: UserRegistrationComponent },
			/* private: user/ */
			{ path: 'change-password', component: ChangePasswordComponent, canActivate: [CheckPortalAccess], data: { title: 'Change Password' } },
		],
	},

	/* system/xxx/xxx/ => lazy loaded */
	{
	  path: 'system',
	  children: [
			/* system/ */
			{ path: 'logs', component: SystemLogMasterComponent, canActivate: [CheckAdminView] },
			{ path: 'users', component: SystemUserMasterComponent, canActivate: [CheckAdminView], data: { title: 'User Management' } },
			{ path: 'users/item/:id', component: SystemUserDetailComponent, canActivate: [CheckAdminView], data: { title: 'Configure User' } },
			{ path: 'roles', component: SystemRoleItemListComponent, canActivate: [CheckAdminView], data: { title: 'Roles & Permissions' }},
			{ path: 'admins', component: SystemAdminListComponent, canActivate: [CheckAdminView], data: { title: 'Team Management' }},
			{ path: 'agents', component: SystemAgentListComponent, canActivate: [CheckAdminView], data: { title: 'Agents Management' }},
			{ path: 'design', component: DesignSystemComponent, canActivate: [CheckAdminView], data: { title: 'Design System' } }, // should we use a sort of 'checkDevelopmentView'? not bad if the client sees, just a bit of sausage-making
	  ],
	  canActivate: [CheckAdminView]
	},

	/* default */
	{ path: '**', redirectTo: 'home' },
];
