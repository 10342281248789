import { NgModule } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import {
  //faMagic, faHome, faBuilding, faCog, faImages,
  faCheckCircle, faPlusCircle, faMinusCircle, faChartLine, faHeart, faClipboard, faPaperPlane, faEnvelope, faPen,
  faCheck, faQuestionCircle, faSyncAlt, faLink, faEllipsisV, faDownload, faStar, faTrash, faCopy, faExclamationTriangle,
  faSync, faExternalLinkAlt, faSignIn, faUser, faCaretDown, faUpload,
  faRightFromBracket, faLaptop, faPhone, faMessage, faHeadset, faKey, faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

import { faCircle, faCommentDots, faChartBar } from '@fortawesome/free-regular-svg-icons';



@NgModule({
	declarations: [],
	imports: [FontAwesomeModule],
	exports: [FontAwesomeModule],
})
export class IconsModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(
			faCircle, faCheckCircle, faPlusCircle, faMinusCircle, faChartLine, faHeart, faLink, faEllipsisV, faPaperPlane,
			faCommentDots, faChartBar, faClipboard, faEnvelope, faPen, faCheck, faQuestionCircle, faSyncAlt, faDownload,
      faStar, faExternalLinkAlt, faSignIn, faSync, faExclamationTriangle, faTrash, faCopy, faUser, faCaretDown, faUpload,
      faRightFromBracket, faLaptop, faPhone, faMessage, faHeadset, faKey, faArrowRight
		);
	}
}
