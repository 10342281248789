<main class="rewards-page">

  <!-- HOMEWRITE REWARDS MAIN -->
  <section class="brand-gradient-block py-5 text-white" data-aos="fade-up">
    <div class="container">
      <!-- Main heading and intro text -->
      <div class="row">
        <div class="col-lg-8">
          <h2 class="display-4 font-weight-bold mb-3">
            Homewrite Rewards
          </h2>
          <p class="lead mb-4">
            By untethering ourselves from the home search, we untether our buyers from the
            high cost of traditional real estate agents. Instead of keeping the entire
            commission (up to 3%) for ourselves, we take a small portion and give the rest
            back to you.
          </p>
        </div>
        <div class="col-lg-4 d-flex align-items-center justify-content-lg-end">
          <!-- max brightness to make this white instead of the normal green color -->
          <img
            src="/images/rewards/homewrite_rewards_logo.png"
            alt="Homewrite Rewards Logo"
            class="img-fluid"
            style="max-width: 180px; filter: brightness(100);"
          >
        </div>
      </div>

      <!-- Why do we do this? -->
      <div class="row mt-5">
        <div class="col-lg-8 col-sm-12">
          <h3 class="h4 font-weight-bold mb-3">
            Why do we do this?
          </h3>
          <p class="mb-4">
            Because you did most of the work! By untethering ourselves from the search,
            we untether you from the high cost of an agent.
          </p>
        </div>

        <!-- Example infographic of Seller -> Homewrite -> Buyer -->
        <div class="col-lg-4 d-flex align-items-center justify-content-lg-end">
          <div class="d-flex align-items-center">
            <div class="text-center mr-4">
              <img
                src="/images/rewards/homewrite_rewards_seller-buyer.webp"
                alt="Seller Buyer"
                style="max-height: 120px;"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-4 d-flex align-items-center justify-content-lg-end">
          <div class="d-flex align-items-center">
            <div class="text-center mr-4">
              <img
                src="/images/rewards/homewrite_rewards_seller-agent.webp"
                alt="Seller Buyer"
                style="max-height: 120px;"
              >
            </div>
          </div>
        </div>

        <div class="col-lg-8 col-sm-12">
          <p class="mb-0">
            Most brokers charge up to 3% of the purchase price for their services,
            to compensate themselves for the time searching with you.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- THE COST OF THE AGENT -->
  <section class="brand-white-block py-5" data-aos="fade-up">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="h3-responsive font-weight-bolder mb-3">
            The cost of the agent
          </h2>
          <p class="body-responsive mb-4">
            The cost of the real estate agent has often been obscured.
            At <strong>Homewrite.com</strong>, we want to have that conversation.
            Here’s how it works:
          </p>
          <ol class="body-responsive homepage-ul">
            <li>The buyer’s agent has historically charged a 2–3% commission for their services.</li>
            <li>Until 2024, this money came from the seller at closing.</li>
            <li>
              In 2024 a settlement was reached after a big lawsuit that determined sellers
              are not required to pay for the buyer’s agent if they don’t want to.
            </li>
            <li>
              If a seller does not offer to pay the buyer’s agent commission,
              the cost will fall to the buyer (ouch!).
            </li>
            <li>
              We have priced our services fairly so in the event the seller does not pay a
              buyer's agent commission, our services cost up to 90% less than
              the traditional buyer's agent.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>

  <!-- THE POWER OF HOMEWRITE REWARDS -->
  <section class="brand-gradient-block py-5 text-white" data-aos="fade-up">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="h3-responsive font-weight-bolder mb-3">
            The power of Homewrite Rewards
          </h2>
          <p class="body-responsive mb-4">
            The money that we pass onto you at closing is a game changer.
            Let us show you some options:
          </p>

          <h3 class="h5-responsive font-weight-bold mb-1">
            Lower your monthly payment
          </h3>
          <p class="body-responsive mb-4">
            Use your Homewrite Reward to buy a lower interest rate and reduce
            the amount of your monthly mortgage payments.
          </p>

          <h3 class="h5-responsive font-weight-bold mb-1">
            Buy a home faster
          </h3>
          <p class="body-responsive mb-4">
            If your monthly payment goes down, you may be able to qualify
            for the home you want quicker than you realized.
          </p>

          <h3 class="h5-responsive font-weight-bold mb-1">
            Increase your budget
          </h3>
          <p class="body-responsive mb-4">
            Or you could lower your rate, and have more spending power
            with the same payment you’d originally been approved for.
          </p>

          <h3 class="h5-responsive font-weight-bold mb-1">
            Make a better offer
          </h3>
          <p class="body-responsive mb-4">
            By giving money back to the seller, you can make your offer more
            competitive without using your own money.
          </p>
        </div>
      </div>

      <div class="logo-bg"></div>
    </div>
  </section>

  <!-- FOOTER (partner-logos are presumably inside the footer now) -->
  <homepage-footer></homepage-footer>
</main>
