import { Component, inject, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, RouterModule } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { SessionService } from '@services/session.service';
import { AuthUserIdentity } from '@models/auth-user-identity';
import { LoadingService } from '@services/loading.service';
import { LogNinja } from '@services/log-ninja.service';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconsModule } from '@app/icons/icons.module';

import { faBars } from '@fortawesome/free-solid-svg-icons';

const $ = (window as any).$;

@Component({
  selector: 'nav-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    IconsModule,
  ],
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavMenuComponent implements OnInit {
  public readonly faBars = faBars;

  public loggedIn = false;
  public loadingRoute = false;
  public userIdentityCurrent: AuthUserIdentity | null = null;
  public identities!: AuthUserIdentity[];

  public scrolled = false;

  public auth = inject(AuthService);
  private _router = inject(Router);
  private _session = inject(SessionService);
  private _logger = inject(LogNinja);
  public loadingService = inject(LoadingService);

  constructor() {
    console.log('navmenu.component.ts constructor', this.userIdentityCurrent);

    // Hook up to the route load event
    this._router.events.subscribe((event: any) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRoute = true;
        this.loadingService.show();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRoute = false;
        this.loadingService.hide();
      }
    });

    // Hook up to the log in status
    this._session.isUserLoggedIn$.subscribe(value => {
      this.loggedIn = value;
      this.userIdentityCurrent = this.auth.getLocalUserIdentityCurrent();
      this.identities = this.auth.getLocalUserIdentities();
      console.log('navmenu.component.ts isUserLoggedIn$', value, this.userIdentityCurrent, this.identities);
    });

    // Closes bootstrap mobile menu when clicking outside of it
    $(document).click(function (event: any) {
      var clickover = $(event.target);
      var _opened = $(".navbar-collapse").hasClass("show");
      if (_opened === true && !clickover.closest('.navbar').length) {
        $(".navbar-toggler").click();
      }
    });
  }

  ngOnInit() {
    this._router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRoute = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRoute = false;
      }
    });
  }

  // Listen for window scroll and update "scrolled" boolean
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollThreshold = 10; // tweak this for how far before the shadow appears
    this.scrolled = window.pageYOffset > scrollThreshold;
  }

  loginToAccount(userIdentity: AuthUserIdentity) {
    this.loadingService.show();
    this.auth.loginToAccount(userIdentity).subscribe({
      next: () => {
        this.loadingService.hide();
        this.autoRedirect();
      },
      error: () => {
        this.loadingService.hide();
        return null;
      }
    });
  }

  autoRedirect() {
    if (this.userIdentityCurrent != null) {
      let url = '/home/' + new Date().getTime();

      this._logger.log('navmenu sending to url: ' + url);

      // We have an identity -> go to main landing
      this._router.navigate([url]);
    }
  }

  login() {
    this._router.navigateByUrl('/');
  }

  closeMobileMenu(event: any) {
    var navbarToggler = document.getElementById('navbar-toggler');
    var isNavbarTogglerVisible = false;

    if (navbarToggler) {
      var style = window.getComputedStyle(navbarToggler);
      isNavbarTogglerVisible = (
        style.display !== 'none' &&
        style.visibility !== 'hidden' &&
        style.opacity !== '0' &&
        style.width !== '0px' &&
        style.height !== '0px'
      );
    }

    if (isNavbarTogglerVisible) {
      $('.navbar-toggler').click();
    }
  }
}
