import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IconsModule } from '@app/icons/icons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRight, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@services/auth.service';
import {FooterComponent} from "@components/footer/footer.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, IconsModule, RouterModule, FooterComponent],
  templateUrl: './home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent {
  public readonly ARROW_RIGHT = faArrowRight;
  public readonly CONTRACT = faFileContract;
  public isLoggedIn = signal(false);

  public auth = inject(AuthService);
  private _router = inject(Router);

  constructor() {
    this.isLoggedIn.set(this.auth.isLoggedIn());
  }
}
