import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Router } from '@angular/router'; // for routing and accessing the route (URL) params
import { AuthService } from "@services/auth.service";
import { UIComponentBase, UIHeaderComponent } from "@buildable/foundation";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { LogNinja } from "@services/log-ninja.service";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconsModule } from "@app/icons/icons.module";

@Component({
  selector: 'change-password',
  standalone: true,
  imports: [
    CommonModule, FormsModule, FontAwesomeModule, IconsModule,
    UIHeaderComponent,
  ],
  templateUrl: './change-password.component.html',
  providers: [LogNinja]
})
export class ChangePasswordComponent extends UIComponentBase implements OnInit, OnDestroy {

  public readonly ARROW_RIGHT = faArrowRight;

  public loading = true;
  public errorMessage = '';
  public successMessage = '';
  public password = '';
  public password1 = '';
  public password2 = '';
  public passwordsValid = false;
  public updating = false;

  public auth = inject(AuthService);
  private _router = inject(Router);
  private _logger = inject(LogNinja);

  constructor() {
    super();
  }

  ngOnInit() {
    this.loading = false;
  }
  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  goToHome() {
    this._router.navigate(['/home']);
  }
  cancel() {
    this._router.navigate(['/user/profile']);
  }

  validatePassword() {
    this.errorMessage = '';
    this.password = this.password.trim();
    this.password1 = this.password1.trim();
    this.password2 = this.password2.trim();

    if (this.password == '') {
      this.errorMessage = 'Please provide your current password.';
      this.passwordsValid = false;
      return false;
    }

    var re = new RegExp('^(?=.*[!@#$%\-_\*\.])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%\-_\*\.]{8,}$');
    if (!re.test(this.password1)) {
      this.errorMessage = 'Your new password must contain at least eight characters, including one lowercase and one uppercase letter, one number, and one special character (ex. ! @ # $ or %).';
      this.passwordsValid = false;
      return false;
    }
    if (this.password1 != this.password2) {
      this.errorMessage = 'Your new password and the password confirmation must match.';
      this.passwordsValid = false;
      return false;
    }

    this.passwordsValid = true;
    return true;
  }


  changePassword() {
    if (!this.validatePassword()) {
      return;
    }
    this.errorMessage = '';
    this.successMessage = '';
    this.updating = true;

    this.auth.changePassword(this.password, this.password1).subscribe({
      next: () => {
        this.updating = false;
        this.successMessage = 'Success! Your password has been changed.';
        this.auth.logout(true, false, '');
        this.goToHome();
      },
      error: (error: any) => {
        this.updating = false;
        // check error.status if custom processing needed
        this.errorMessage = error.statusText;
        return null;
      },
      complete: () => {
        this._logger.log('update password finished');
      }
    });
  }
}
