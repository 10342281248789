<div class="container mb-5" style="font-size: 14px;">
  <div class="col-12">
    <h4 class="h4-responsive font-weight-bolder">Terms and Conditions</h4>
    <p class="mb-4">
      Our Terms and Conditions were last updated on 12/19/2024. Please read these terms and conditions carefully before
      using Our Service.
    </p>
    <p class="mb-4">
      Welcome to Homewrite.com. These Terms and Conditions ("Terms") constitute a legally binding agreement between You
      ("Client") and Us, Homewrite.com, ("Company") regarding the Services provided through this platform. By signing up
      and engaging our services, you agree to these Terms.
    </p>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">PART I - DEFINITIONS</h5>
    <p class="mb-4">
      The words of which the initial letter is capitalized have meanings defined under the following conditions. The
      following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
    </p>
    <ul class="homepage-ul">
      <li>
        “Account” means a unique account created for You to access our Service or parts of our Service and represents a
        legal relationship between You and the Company
      </li>
      <li>
        “Agreement” or “Terms” refers, collectively, to all the Terms, conditions, notices contained or referenced in this
        document (the “Terms of Service”) and all other operating rules, policies (including the Homewrite.com Privacy
        Policy) and procedures that we may publish from time to time on the Website.
      </li>
      <li>
        “Company” (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to the company
        Homewrite.com (entity name +principal place of business)
      </li>
      <li>
        “Country” refers to the United States of America, where Company is located.
      </li>
      <li>
        “Content” refers to Content featured or displayed through the Websites, including without limitation text, data,
        articles, images, files, photographs, graphics, software, applications, designs, features, and other materials
        that are available on the Websites or otherwise available through the Services.
      </li>
      <li>
        “Device” means any device that can access the Service such as a computer, a cell phone or a digital tablet.
      </li>
      <li>
        “Feedback” means feedback, innovations or suggestions sent by You regarding the attributes,
      </li>
      <li>
        “Offer to Closing” means the entire process from the moment a buyer submits an offer on a property to the final
        step of signing all necessary documents and officially taking ownership of the home.
      </li>
      <li>
        “Property” means the property that Client has chosen independently for which to use Homewrite.com’s services
      </li>
      <li>
        “Scope” refers to the boundaries of service and limitations as described herein.
      </li>
      <li>
        “Service” or “Services” refers to the applications, software, products, and services provided by the Company.
      </li>
      <li>
        “Terms and Conditions” (also referred as "Terms") mean these Terms and Conditions that form the entire agreement
        between You and the Company regarding the use of the Service. This Terms and Conditions Agreement was drafted by
        Moultray Law, PS.
      </li>
      <li>
        “Third-Party Service” means any services or content (including data, information, products or services) provided
        by a third-party that may be displayed, included or made available by the Service.
      </li>
      <li>
        “User”, “You,” and “Your” refer to the individual person, company or organization that has visited or is using the
        Website or Service; that accesses or uses any part of the Account, or that directs the use of the Account in the
        performance of its functions. A User must be at least 18 years of age.
      </li>
      <li>
        “Website” refers to Homewrite.com accessible by Homewrite.com and Homewrite.AI [and any other domains associated
        with or related to the business of Homewrite.com].
      </li>
    </ul>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">PART II – GENERAL TERMS</h5>
    <p class="mb-4">
      You should review these Terms periodically as we may update them from time to time:
    </p>
    <p class="mb-4">
      BY ACCESSING OR USING THE SERVICES, YOU AGREE THAT YOU AND YOUR AGENTS ARE BOUND BY THESE TERMS OF SERVICE. THESE
      TERMS INCLUDE AN ARBITRATION AGREEMENT, WAIVER OF CLASS ACTION RIGHTS, AND LIABILITY LIMITATIONS.
    </p>
    <p class="mb-4">
      YOU MAY NOT ACCESS OR USE THIS SERVICE IF: (I) YOU CHOOSE NOT TO AGREE TO THE TERMS, (II) YOU ARE UNDER 18 YEARS OF
      AGE, (III) YOU DO NOT HAVE THE FULL POWER AND AUTHORITY TO ENTER INTO AND FOLLOW THESE TERMS, OR (IV) YOUR AGREEMENT
      TO AND FOLLOWING OF THESE TERMS DOES OR WILL BREACH OR CONFLICT WITH ANY OTHER AGREEMENT OR ARRANGEMENT YOU HAVE
      WITH SOMEONE ELSE OR OTHERWISE VIOLATES THE LAW.
    </p>
    <p class="mb-4">
      We reserve the right to revise these Terms. Changes to these Terms are effective upon posting on our Website. After
      such posting, your continued use of the Websites and/or Services constitutes your acceptance of those changes.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">A. Services Homewrite.com Provides</h4>
    <ol class="homepage-ul">
      <li>Complimentary strategy call from an agent</li>
      <li>Drafting the offer – available 24/7</li>
      <li>Presenting and negotiating the offer</li>
      <li>Coordinating inspection of property</li>
      <li>Coordinating with escrow company</li>
      <li>Contracting with third-party transaction coordination services</li>
      <li>Introducing clients and lenders</li>
      <li>Handling transmission of PSA and addendums to lender</li>
      <li>Handing over keys at closing.</li>
    </ol>

    <h4 class="h4-responsive font-weight-bolder mb-3">B. Services Homewrite.com Does Not Provide</h4>
    <p class="mb-4">
      Client acknowledges and agrees that Homewrite.com is an “offer to closing” model but does not participate in
      clients’ viewing of homes. Homewrite’s services begin once the Client has found the home they want to purchase.
    </p>
    <p class="mb-4">
      Specifically, Homewrite.com does not:
    </p>
    <ol class="homepage-ul">
      <li>
        <strong>Conduct Property Showings:</strong><br>
        Homewrite.com does not arrange or accompany the Client to property showings. Clients must tour properties during
        open houses or coordinate property viewings independently or with the listing agent.
      </li>
      <li>
        <strong>Advise on Financing or Loan Options:</strong><br>
        Homewrite.com does not provide advice regarding financing options, loan terms, or mortgage-related matters.
        Clients are encouraged to consult with a lender or financial advisor.
      </li>
      <li>
        <strong>Inspect or Assess Properties:</strong><br>
        Homewrite.com does not conduct property inspections or advise on the condition of a property. Clients are
        encouraged to hire licensed inspectors or contractors for assessments. We will disclose any relevant information
        that we find out independently or that is disclosed to us.
      </li>
      <li>
        <strong>Provide Escrow or Closing Services:</strong><br>
        Homewrite.com does not provide its own escrow or closing processes. These functions are handled by the escrow or
        title company selected by the parties.
      </li>
      <li>
        <strong>Verify Compliance with Local Ordinances or Requirements:</strong><br>
        Homewrite.com does not verify Property compliance with local zoning laws, building codes, or other regulations.
        Clients are solely responsible for verifying such compliance before making an Offer.
      </li>
      <li>
        <strong>Offer Legal Advice:</strong><br>
        Homewrite.com is not a law firm and does not provide legal advice or draft custom contract provisions. Clients are
        encouraged to seek independent legal counsel for any legal questions or concerns.
      </li>
      <li>
        <strong>Handle Post-Sale Disputes:</strong><br>
        Homewrite.com does not mediate, resolve, or assist with disputes that may arise after a sale has been completed,
        including but not limited to issues related to repairs, non-disclosures, misrepresentations, defects, title
        issues, boundary disputes, or any other matter arising from the transaction. Buyers and sellers are solely
        responsible for addressing any post-sale disputes through negotiation, legal proceedings, or other means outside
        the scope of Homewrite.com's services. Homewrite.com expressly disclaims any responsibility or liability for such
        matters and encourages parties to seek appropriate legal or professional advice to resolve any issues.
      </li>
    </ol>

    <p class="mb-4">
      Client acknowledges that they have received and reviewed the Law of Real Estate Agency pamphlet as required by
      Washington state law (RCW 18.86.030(1)) Client understands and agrees that Homewrite.com’s services are limited.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">C. Prohibited Use</h4>
    <p class="mb-4">
      Homewrite.com reserves the right to monitor our Services for the purpose of determining that use of the Services and
      Websites complies with these Terms. YOU AGREE THAT YOU WILL NOT, DIRECTLY OR INDIRECTLY:
    </p>
    <ol class="homepage-ul">
      <li>
        Use the Service, including but not limited to transmitting Content, for any unintended purposes or in a manner or
        violates these Terms or any law, rule of professional conduct, or regulation; or in a manner that abuses,
        threatens, defames, libels, incites hatred or violence toward, victimizes, intimidates, or otherwise harasses any
        person or group of persons.
      </li>
      <li>
        Use the Service, including but not limited to transmitting Content, in a manner that abuses, threatens, defames,
        libels, incites hatred or violence toward, victimizes, intimidates, or otherwise harasses any person or group of
        persons;
      </li>
      <li>
        Engage in any activity that constitutes the unauthorized practice of law, including, but not limited to, drafting
        or modifying legal documents, providing legal advice, or representing any party in a legal capacity without proper
        licensure.
      </li>
      <li>
        Except as expressly permitted by these Terms, copy, reproduce, modify, distribute, display, create derivative
        works of, or transmit any Content on the website or part of the Service;
      </li>
      <li>
        Use the Service or any of our marks commercially, for benchmarking, or to compile Content for a competitive
        product or Service, or;
      </li>
      <li>
        Reverse engineer, decompile, tamper with, or disassemble the technology used to provide the Service (except as and
        only to the extent any foregoing restriction is prohibited by a non¬waivable provision of applicable law or to the
        extent as may be permitted by the licensing Terms governing use of any open¬source components), or otherwise
        attempt to obtain source code;
      </li>
      <li>
        Interfere with or damage the Service or our servers, including but not limited to the use of viruses, malware,
        harmful code, denial of service attacks, hacking or anything that could be construed as hacking, forged
        information, or similar methods or technology, or through any form of excessive bulk activity (for example,
        spamming), or relay any other form of unsolicited advertising or solicitation through our servers, such as
        get-rich-quick schemes;
      </li>
      <li>
        Reproduce, duplicate, copy, sell, resell, compile, or exploit any portion of the Service, use of the Service, or
        access to the Service without Company’s express written permission;
      </li>
      <li>
        Impersonate or misrepresent your identity or affiliation with a person, entity or organization, or use a false
        identity;
      </li>
      <li>
        Attempt to obtain unauthorized access to the Service or any materials or information not intentionally made
        available through the Service;
      </li>
      <li>
        Violate, misappropriate, or infringe a third party’s intellectual property or other right through the Service; or
      </li>
      <li>
        Interfere with any third party’s ability to use or enjoy, or our ability to provide, the Service (e.g., by
        attempting to disrupt or tamper with Company’s servers in ways that could harm our Website or Service, to place
        undue burden on Company’s servers through automated means, or to access Company’s Service in ways that exceed your
        authorization).
      </li>
    </ol>
    <p class="mb-4">
      Homewrite.com prohibits the conduct and Content described above and, without obligation, may take measures to
      prevent or eliminate such conduct and Content on Homewrite.com (e.g., pre-screening, monitoring, and/or removing
      offending Content/conduct). Refer to section on termination rights to review Homewrite.com’s remedies for violation
      of terms.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">D. Intellectual Property Notice</h4>
    <p class="mb-4">
      Homewrite.com’s Rights to Content: Homewrite.com and our licensors, vendors, agents, and/or our Content providers
      retain ownership of all intellectual property rights of any kind related to the Websites and Services. We reserve
      all rights that are not expressly granted to you under this Agreement or by law. The look and feel of the Websites
      and Services is copyright © (TBD) All rights reserved. You may not duplicate, copy, or reuse any portion of the
      HTML/CSS, Javascript, or visual design elements or concepts without express written permission from Homewrite.com.
      Homewrite.com makes available its Content, Services, and Website strictly for authorized use by Users. This does not
      mean we are assigning any ownership or right of exploitation to Users.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">E. Legal Disclaimers</h4>
    <ol class="homepage-ul">
      <li>
        <strong>Warranties:</strong> Homewrite.com makes no representations or warranties regarding the outcomes of
        services provided and does not guarantee results for real estate transactions.
      </li>
      <li>
        <strong>Company Does Not Provide Legal Advice:</strong> Homewrite.com does not provide legal advice or services
        and is not a law firm. Any information provided on our platform, including guidance or templates, is for
        informational purposes only and should not be considered legal advice. Users should consult a licensed attorney
        for any legal matters or advice specific to their circumstances. Use of our Services does not establish an
        attorney-client relationship between you and Homewrite.com or any of its representatives.
      </li>
      <li>
        <strong>User Responsibility:</strong> The Client is solely responsible for ensuring that the use of
        Homewrite.com’s Services complies with applicable local, state, and federal laws. Homewrite.com is not responsible
        for any violations of law or failure to comply with legal or regulatory requirements arising from Your use of Our
        Services.
      </li>
      <li>
        <strong>Scope of Services:</strong> Homewrite.com provides Offer to Closing real estate services. The scope of our
        Services is explicitly defined within these Terms and on our Website. By engaging Homewrite.com, you acknowledge
        that Homewrite.com does not participate in the home search and that Homewrite.com does not guarantee specific
        outcomes for real estate transactions. However, our fee is contingent upon the successful completion of the
        transaction, and You are only required to pay if the transaction is completed.
      </li>
      <li>
        <strong>Liability Limitation:</strong> To the fullest extent permitted by law, Homewrite.com disclaims all
        warranties, express or implied, including but not limited to warranties of merchantability, fitness for a
        particular purpose, or non-infringement. Homewrite.com is not liable for any indirect, incidental, special,
        consequential, or punitive damages arising from the use of our Services, even if advised of the possibility of
        such damages. The aggregate liability of Homewrite.com shall not exceed the amount paid for the Services.
      </li>
      <li>
        <strong>Data Privacy:</strong> Homewrite.com collects and processes user data in compliance with applicable
        privacy laws, including Washington State laws. For more information about how your data is collected, used, and
        protected, please refer to our Privacy Policy. By using our Services, you consent to the collection and processing
        of your data in accordance with these policies. You further acknowledge that Homewrite.com implements reasonable
        cybersecurity measures to protect your information; however, Homewrite.com is not liable for unauthorized access
        to your data by third parties, provided such measures were in place.
      </li>
      <li>
        <strong>Third-Party Services:</strong> Homewrite.com may use third-party services, such as payment processors, to
        facilitate certain aspects of the Services. While Homewrite.com endeavors to work with reputable third parties, we
        make no guarantees or representations regarding their performance. Users agree to be bound by the terms and
        conditions of any third-party services used in conjunction with Homewrite.com’s Services.
      </li>
      <li>
        <strong>No Guarantee of Results Disclaimer:</strong> Homewrite.com makes no representations, guarantees, or
        warranties regarding the outcomes or results of any real estate transaction facilitated through our Services. Any
        projections, estimates, or opinions provided are purely for informational purposes and do not constitute a
        guarantee.
      </li>
    </ol>
    <p class="mb-4">
      Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy
      Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and
      disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy
      rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
    </p>
    <p class="mb-4">
      You agree to provide all necessary information and cooperation required for the Company to perform the Services
      outlined in this Agreement.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">F. Payments to Homewrite.com</h4>
    <p class="mb-4">
      To use these Services, Users must provide Company with a valid credit card for payment. Homewrite.com shall make
      commercially reasonable efforts to collect compensation for Services rendered from the seller of the Property
      defined in the Agreement. If payment by the Seller fails, User will be charged for services depending on the
      purchase price of the transaction as laid out below in “Fee Structure”. Fees are non-refundable unless otherwise
      specified by Company in writing.
    </p>
    <ol class="homepage-ul">
      <li>
        <strong>Fee Structure:</strong> Homewrite.com charges a fee for each transaction or service engagement. The prices
        are a flat fee of $5,000 for purchase prices up to and including $2,000,000.00 (two million dollars) (USD); and a
        flat fee of $10,000 for purchase prices of $2,000,000.01 (two million dollars and one cent) (USD) or more based on
        the listing price of the home. This fee is contingent on the Property closing.
      </li>
      <li>
        While we will maintain the terms and conditions for the duration of contract unless otherwise agreed to by all
        parties, future transactions may required updated terms presented to you prior to continued use of services for
        subsequent transactions.
      </li>
    </ol>

    <h4 class="h4-responsive font-weight-bolder mb-3">G. Consent to Electronic Signature</h4>
    <p class="mb-4">
      By using Homewrite.com’s Services, you agree to conduct transactions electronically and consent to the use of
      electronic signatures.
    </p>
    <ol class="homepage-ul">
      <li>
        <strong>Scope of Consent:</strong> This consent applies to all documents requiring your signature, including but
        not limited to: Terms and Conditions, Agency Appointment Agreement, Offers prepared by Company.
      </li>
      <li>
        <strong>Hardware and Software Requirements:</strong> You must have the following: (a) a device with internet
        access; (b) a PDF reader and an updated browser; (3) an active email address.
      </li>
      <li>
        <strong>Acknowledgement:</strong> By proceeding, you confirm that you can access and retain this Agreement and you
        agree to electronically sign and receive documents.
      </li>
    </ol>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">PART III – LIMITATION OF DAMAGES</h5>
    <p class="mb-4">
      Homewrite.com’s liability for any damages you or a third party may incur is limited as follows:
    </p>
    <p class="mb-4">
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL HOMEWRITE.COM, ITS MEMBERS, AFFILIATES,
      DIRECTORS, ASSOCIATES, PARTNERS, AGENTS, EMPLOYEES, OR REPRESENTATIVES BE LIABLE FOR ANY INDIRECT, EXEMPLARY,
      PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF
      PROFITS, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, PERSONAL INJURY, LOSS OF PRIVACY, OR ATTORNEYS’
      FEES), ARISING OUT OF OR IN ANY WAY RELATED TO THE SERVICES OR WEBSITES, REGARDLESS OF THE CAUSE OF ACTION ON WHICH
      THEY ARE BASED, EVEN IF HOMEWRITE.COM OR SUCH OTHER PERSONS OR ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES.
    </p>
    <p class="mb-4">
      IN NO EVENT WILL THE CUMULATIVE, AGGREGATE LIABILITY OF HOMEWRITE.COM IN CONNECTION WITH THE WEBSITES AND SERVICES
      EXCEED $5,000 OR THE AMOUNTS YOU PAID TO HOMEWRITE.COM IN CONNECTION WITH THE PARTICULAR PRODUCT OR SERVICE AT
      ISSUE, WHICHEVER IS GREATER.
    </p>
    <p class="mb-4">
      YOU MAY HAVE OTHER RIGHTS WHICH MAY VARY FROM STATE TO STATE OR IN OTHER JURISDICTIONS OR COUNTRIES. BECAUSE SOME
      STATES OR JURISDICTIONS MAY NOT ALLOW LIMITATIONS ON THE DURATION OF IMPLIED WARRANTIES, OR LIMITATIONS ON OR
      EXCLUSIONS OF CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO A USER OR ENDUSER
      DEPENDING ON HER, HIS, OR THEIR STATE OR NATION OF RESIDENCE.
    </p>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">PART IV – INDEMNITY</h5>
    <p class="mb-4">
      You agree to indemnify Homewrite.com for all costs in connection with claims.
    </p>
    <p class="mb-4">
      You agree to defend, indemnify, and hold Homewrite.com and its affiliates, and their respective employees,
      representatives, agents, attorneys, directors, officers, and members harmless from any damage, loss, cost, or
      expense (including but not limited to attorneys’ fees and costs) incurred in connection with any third-party claim,
      demand, or action brought or asserted against any of the indemnified parties: (i) alleging facts or circumstances
      that would constitute a breach of any provision of these Terms by you; (ii) arising from or related to your use of
      the Services; or (iii) arising from or related to your User Content. If you are required to provide indemnification,
      Homewrite.com may, in its sole and absolute discretion, control any claim at your sole cost and expense. You may not
      settle, compromise, or in any other manner dispose of any claim without Homewrite.com’s consent.
    </p>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">
      PART V – CHANGES TO THE SERVICE; TERMINATION
    </h5>
    <p class="mb-4">
      Homewrite.com may terminate or modify the Services or your use of the Websites at any time unless termination
      procedures are otherwise provided in a Software License Agreement the User has separately entered into with
      Homewrite.com (in which case termination is governed by that agreement) or in another section of this Agreement.
      Company may, in its sole and absolute discretion and without any liability, modify, suspend, or discontinue any
      aspect of the Services, temporarily or permanently, at any time. We may deny you access to all or part of the
      Services at any time for violation of these Terms and Conditions, illegal or fraudulent activities, security risk or
      misuse of platform. Decisions to terminate or modify services will not be based on any factor prohibited by federal,
      state, or local Fair Housing Laws. If we Terminate your use of the Services, you must cease all use of the Services
      immediately. These Terms will survive indefinitely unless and until Company chooses to terminate them. Unless
      otherwise required by law, we will notify you when we make changes to these Terms.
    </p>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">PART VI – DISPUTES</h5>
    <h4 class="h4-responsive font-weight-bolder mb-3">A. Initial Dispute Resolution</h4>
    <p class="mb-4">
      If you have any dispute with us, you agree to first contact us at <a
        href="mailto:support@homewrite.com">support&#64;homewrite.com</a> with a brief, written description of the dispute and
      your contact information. You must allow sixty (60) days for us to attempt to reach an amicable resolution of the
      issue before taking any formal action.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">B. Governing Law</h4>
    <p class="mb-4">
      These Terms, your use of the Service, and any matters relating to Homewrite.com will be governed by the laws of the
      state of Washington, without regard to conflict of laws principles.
    </p>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">PART VII – ARBITRATION</h5>
    <h4 class="h4-responsive font-weight-bolder mb-3">A. Arbitration Requirement</h4>
    <p class="mb-4">
      Any claims arising out of, relating to, or connected with these Terms must be asserted individually in binding
      arbitration administered by the American Arbitration Association (“AAA”) in accordance with its Commercial
      Arbitration Terms and the AAA Supplementary Procedures for Consumer-Related Disputes. Arbitration proceedings,
      including desk, phone, or video conferences, will be held in Seattle, Washington, to mitigate costs.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">B. Arbitrator's Authority</h4>
    <p class="mb-4">
      The arbitrator shall not conduct any form of class or collective arbitration nor consolidate claims by or for
      individuals. The arbitrator has exclusive authority to resolve any disputes concerning the interpretation,
      applicability, enforceability, or formation of these Terms, including claims that all or part of the Terms are void
      or voidable. Judgment on the arbitrator's award may be entered in any court of competent jurisdiction.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">C. Exceptions to Arbitration</h4>
    <ol class="homepage-ul">
      <li>
        If Homewrite.com reasonably believes you have violated or threatened to violate these Terms, we may seek
        injunctive or other appropriate relief in any court of competent jurisdiction.
      </li>
      <li>
        Any claim of $1,000 or less may, at the option of the claiming party, be resolved in small claims court in King
        County, Washington, provided the claim and the parties are within the court's jurisdiction.
      </li>
    </ol>
    <p class="mb-4">
      BY AGREEING TO THESE TERMS, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO A COURT TRIAL (OTHER THAN SMALL
      CLAIMS COURT AS PROVIDED ABOVE) OR TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER
      REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT, ARBITRATION, OR
      OTHER PROCEEDING FILED AGAINST US AND/OR RELATED THIRD PARTIES.
    </p>
    <p class="mb-4">
      REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE OF
      THE WEBSITE, SERVICE, OR THE TERMS MUST BE FILED WITHIN ONE YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION ARISES OR IT
      WILL BE FOREVER BARRED
    </p>
    <p class="mb-4">
      To opt out of the arbitration agreement, email us at <a
      href="mailto:support@homewrite.com">support&#64;homewrite.com</a> within 30 days of accepting these Terms
      with 'Arbitration Opt-Out' in the subject line.
    </p>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">PART VIII – ATTORNEY’S FEES</h5>
    <p class="mb-4">
      In the event of any legal action, arbitration, or other proceeding arising out of or related to these Terms, the
      prevailing party shall be entitled to recover its reasonable attorneys’ fees and costs, in addition to any other
      relief to which it may be entitled. For purposes of this provision, “prevailing party” includes a party who achieves
      dismissal or withdrawal of the opposing party’s claim(s), whether voluntarily or involuntarily, with or without
      prejudice.
    </p>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">PART IX – MISCELLANEOUS</h5>
    <h4 class="h4-responsive font-weight-bolder mb-3">A. Entire Agreement and Governing Terms</h4>
    <p class="mb-4">
      Except as provided in any applicable Software License Agreement and/or Data Processing Addendum between
      Homewrite.com and the User, these Terms (which incorporate our Privacy Policy) constitute the entire agreement
      between the parties regarding Homewrite.com and supersede any prior agreements.
    </p>
    <p class="mb-4">
      These Terms will be binding upon the successors and permitted assigns of you and Homewrite.com. You may not transfer
      your rights or obligations under these Terms without the prior written consent of Homewrite.com. Homewrite.com may
      freely transfer or assign its rights and obligations under these Terms, in whole or in part, without prior notice or
      consent.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">B. Liability and Use of Services</h4>
    <p class="mb-4">
      Under no circumstances will Homewrite.com or its licensors be held liable for any delay or failure in performance
      resulting directly or indirectly from an event beyond Homewrite.com’s reasonable control.
    </p>
    <p class="mb-4">
      The Services are controlled and offered by Homewrite.com from the United States of America. Unless provided
      elsewhere in our agreement with you, Homewrite.com makes no representation that the Services may be used in or
      comply with the laws of other locations. Use from other locations is at your own risk. By using the Services, you
      consent to the processing of your data in the United States of America.
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">C. Communications and Legal Notices</h4>
    <p class="mb-4">
      By providing your email address, you agree that Homewrite.com may send you emails related to the Services or your
      account. You can opt-out of promotional emails by following the instructions in our messages; however, you cannot
      opt-out of administrative emails regarding the Services you request. Homewrite.com may communicate with you via
      email or by posting notices on its website, and you agree that such electronic communications satisfy any legal
      requirement for written communication.
    </p>
    <p class="mb-4">
      If you have a legal requirement to communicate with or provide notice to Homewrite.com, you must use the following
      address:
      <br>
      [Homewrite.com – headquarters]
    </p>

    <h4 class="h4-responsive font-weight-bolder mb-3">D. Transfer by Homewrite.com</h4>
    <p class="mb-4">
      Homewrite.com reserves the right to transfer or assign its rights and obligations under these Terms, in whole or in
      part, without prior notice or consent, including but not limited to instances of merger, acquisition, sale of
      assets, or by operation of law. Such transfer or assignment will not affect your rights or obligations under these
      Terms.
    </p>
    <p class="mb-4">
      You acknowledge that you have read, understood, and are bound by these Terms of Service, as well as by the Privacy
      Policy of Homewrite.com. If you have any questions about this agreement, please email us at <a
      href="mailto:support@homewrite.com">support&#64;homewrite.com</a>.
    </p>
    <p class="mb-4">
      Additional Provisions<br>
      • These Terms do not create any third-party beneficiary rights.<br>
      • A party’s failure or delay in exercising any right, power, or privilege under these Terms will not waive its right
      to exercise such right, power, or privilege in the future.<br>
      • If any provision of these Terms is declared invalid or unenforceable, that provision will be deemed automatically
      amended to the minimum extent necessary to conform to the requirements for validity, and the remaining provisions
      will remain in full force and
    </p>
  </div>

  <div class="col-12">
    <h5 class="h5-responsive font-weight-bolder">INTEGRATION</h5>
    <p class="mb-4">
      These Terms, together with any applicable Software License Agreement, Data Processing Addendum, and our Privacy
      Policy, constitute the entire agreement between you and Homewrite.com regarding the subject matter herein. These
      Terms supersede and replace any prior or contemporaneous agreements, understandings, representations, or
      communications, whether written or oral, relating to the Services. No amendment, modification, or waiver of these
      Terms will be effective unless made in writing and signed by an authorized representative of Homewrite.com.
    </p>

    <h5 class="h5-responsive font-weight-bolder">TERMINATION</h5>
    <p class="mb-4">
      Homewrite.com reserves the right to terminate the provision of Services to any client at its sole discretion,
      without notice, for any reason, including but not limited to Client’s breach of this Agreement or conduct that could
      harm the Company’s reputation.
    </p>

    <h5 class="h5-responsive font-weight-bolder">MODIFICATION OF TERMS</h5>
    <p class="mb-4">
      Homewrite.com reserves the right to update or modify these Terms and Conditions at any time without prior notice.
      Updates or modifications to these Terms will apply only to new properties or transactions that you engage
      Homewrite.com to assist with after the updated Terms take effect. For any ongoing transactions or properties you
      have already engaged Homewrite.com on, the Terms and Conditions in effect at the time of your engagement will
      continue to govern. Continued use of the Service for new engagements after updates constitutes acceptance of the
      updated Terms.
    </p>

    <h5 class="h5-responsive font-weight-bolder">ENTIRE AGREEMENT</h5>
    <p class="mb-4">
      These Terms and Conditions constitute the entire agreement between Client and Homewrite.com concerning the services
      provided and supersedes all prior agreements, whether written or oral.
    </p>
    <p class="mb-4">
      By proceeding with the services on Homewrite.com, you, as the Client, agree to be bound by the above terms and
      conditions. Please read this Agreement carefully and contact us with any questions before proceeding.
    </p>
  </div>
</div>
