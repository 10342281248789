import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UIAlertItem, UIComponentBase, UIEditViewModel, UIEditViewValidateResult, UIEditViewItem, UIAlertComponent, UIHeaderComponent, UIEditViewComponent } from '@buildable/foundation';

import { CacheDataService } from '@services/cache-data.service';
import { SystemUsersService } from '@services/system-users.service';
import { SystemUserModel } from '@models/system-user-model';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'system-user-detail',
	standalone: true,
	imports: [
		CommonModule,
		UIAlertComponent, UIHeaderComponent, UIEditViewComponent,
	],
	templateUrl: './system-user-detail.component.html',
	providers: [SystemUsersService], // does not need to be SINGLETON, or global (saves on space)
})
export class SystemUserDetailComponent extends UIComponentBase implements OnInit, OnDestroy {
	public dataId: number = 0;
	public data: SystemUserModel | null = null;
	public editViewModel: UIEditViewModel;
	public editAlert: UIAlertItem = new UIAlertItem();

	public auth = inject(AuthService);
	public _userService = inject(SystemUsersService);
	public _router = inject(Router);
	public _route = inject(ActivatedRoute);
	public _cacheData = inject(CacheDataService);

	// for routing to /users/id
	constructor() {
		super();
		this.editViewModel = new UIEditViewModel({
			showInline: true,
			showReload: false,
			editViewItems: [
				new UIEditViewItem({ label: 'Active', field: 'isActive', type: 'checkbox' }),
				//{ label: 'Timesheet', field: 'isTimesheetRequired', type: 'checkbox' },
				new UIEditViewItem({ label: 'First Name', field: 'firstName', type: 'text', required: true }),
				new UIEditViewItem({ label: 'Last Name', field: 'lastName', type: 'text', required: true }),
				new UIEditViewItem({ label: 'Email', description: 'The user will log in with this address.', field: 'emailAddress', type: 'text', required: true }),
				new UIEditViewItem({ label: '', field: '', type: 'group-label', description: 'To change the user\'s password, enter a new password below. Leave it blank to keep the current user\'s password.'}),
				new UIEditViewItem({ label: 'New Password', field: 'password', type: 'password', description: 'Must contain at least eight characters, including one lowercase and one uppercase letter, one number, and one special character (ex. ! @ # $ or %).', required: false, disabled: false }),
				// can't edit systemRoles anymore (Max 3/3/20)
				//new UIEditViewItem({ label: 'Role(s)', field: 'systemRoles', type: 'checkbox-list', required: true }),
			]
		});
		// subscribe to events when the model will be ready
		this.addSubscriptions(this.editViewModel.initialize$.subscribe(() => {
			this.addSubscriptions(
				this.editViewModel.reload$.subscribe(() => this.reload()),
				this.editViewModel.cancel$.subscribe(() => this.goToMaster()),
				this.editViewModel.save$.subscribe(() => this.onSubmit()),
				this.editViewModel.error$.subscribe((message) => this.editAlert.errorMessage = message),
			);
		}));

		// setup the validation handlers
		this.editViewModel.beforeValidateDataCallback = (data: any) => { this.editAlert.reset(); }; // clear the error messages before validating
		this.editViewModel.validateDataCallback = (data: any) => { return this.onValidateData(data); } // hook to the local method (note we must use a lambda function, or 'this' will lose scope inside the function)
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}

	ngOnInit() {
		this._cacheData.loadCache();

		// hook up to the route: note this will get re-executed routinely,
		// but the component won't get recreated, so any initialization depending on routes need to be done in the handler below
		this.addSubscriptions(this._route.params.subscribe(params => {
			this.data = null;
			this.editViewModel.data = null;
			this.dataId = +params['id']; // (+) converts string 'id' to a number
			this.reload();
		}));
	}

	reload() {
		this.editAlert.reset();
		this.editAlert.loading = true;

		if (this.dataId == this.auth.getLocalUserId()) {
			this.editAlert.errorMessage = 'You cannot edit yourself.';
			this.editAlert.loading = false;
		}
		else if (this.dataId == 0)
		{
			this._userService.getItemNew().subscribe({
				next: (item) => {
					this.data = item;
					this.editViewModel.data = this.data;
					this.editViewModel.reset();
					this.editAlert.loading = false;
				},
				error: (err) => {
					this.editAlert.loading = false;
					this.editAlert.errorMessage = 'Item not found. Reason: ' + err.statusText;
				}
			});
		} else {
			this._userService.getItemById(this.dataId).subscribe({
				next: (item) => {
					this.data = item;
					this.editViewModel.data = this.data;
					this.editViewModel.reset();
					this.editAlert.loading = false;
				},
				error: (err) => {
					this.editAlert.loading = false;
					this.editAlert.errorMessage = 'Item not found. Reason: ' + err.statusText;
				}
			});
		}
	}

	onValidateData(data: any): UIEditViewValidateResult {
		// we use this method only to validate the password, but we could check the email too.
		let user = <SystemUserModel>data;
		let result = new UIEditViewValidateResult();

		// validate password - only when new, or non empty on editing!
		let password = (user.password || '').trim();
		if (this.dataId == 0 || password.length > 0) {
			if (password.length < 8) {
				result.appendError('Your new password must be at least 8 characters.');
				result.isValid = false;
			} else {
				let regex = new RegExp('^(?=.*[!@#$%])(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d!@#$%]{8,}$'); // lower + upper + number + special. Note: must use double escape)
				if (!password.match(regex)) {
					result.appendError('Your new password must contain at least eight characters, including one lowercase and one uppercase letter, one number, and one special character (ex. ! @ # $ or %).');
					result.isValid = false;
				}
			}
		}

		return result;
	}

	onSubmit() {
		this.editAlert.reset();
		this.editViewModel.saving = true;
		if (this.dataId == 0) {
			this._userService.post(this.data!).subscribe({
				next: () => {
					this.goToMaster();
				},
				error: (err) => {
					this.editViewModel.saving = false;
					this.editAlert.errorMessage = 'Failed to insert user. Reason: ' + err.statusText;
					this.scrollWindowTop();
				}
			});
		} else {
			this._userService.put(this.dataId, this.data!).subscribe({
				next: () => {
					this.goToMaster();
				},
				error: (err) => {
					this.editViewModel.saving = false;
					this.editAlert.errorMessage = 'Failed to update user. Reason: ' + err.statusText;
					this.scrollWindowTop();
				}
			});
		}
	}

	goToMaster() {
		this._router.navigate(['/system/users']);
	}

	override scrollWindowTop() {
		window.scroll(0, 0);
	}
}
