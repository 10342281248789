<main class="listing-services-page">

  <!-- TOP HEADER (Gradient Block) -->
  <section class="brand-gradient-block py-7 text-white" data-aos="fade-up">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="display-4 font-weight-bold mb-3">
            Our Listing Services
          </h2>
          <p class="lead mb-0">
            We remove the guesswork and provide the best marketing available on every home we sell.
          </p>
        </div>
      </div>

      <div alt="homewrite logo" class="logo-bg"></div>
    </div>
  </section>

  <!-- LOWER CONTENT (White Block) -->
  <section class="brand-white-block py-5" data-aos="fade-up">
    <div class="container">
      <div class="row">
        <!-- LEFT COLUMN -->
        <div class="col-md-6">
          <h3 class="h4-responsive font-weight-bold mb-2">
            What We Charge
          </h3>
          <p class="body-responsive mb-5">
            Instead of charging a percentage of the sales price, we only charge a
            percentage of the equity you have in the home.
          </p>

          <h3 class="h4-responsive font-weight-bold mb-2">
            A Standard of Service
          </h3>
          <p class="body-responsive">
            Our listing services are defined at the corporate level, so you know exactly what you’re getting.
          </p>
        </div>

        <!-- RIGHT COLUMN -->
        <div class="col-md-6 mt-5 mt-md-0">
          <h3 class="h4-responsive font-weight-bold mb-3">
            What We Do
          </h3>
          <ul class="body-responsive homepage-ul">
            <li>4K cinematic video</li>
            <li>Luxury professional photos</li>
            <li>Brochures on commercial cardstock</li>
            <li>Custom website for your home</li>
            <li>3D digital home tours</li>
            <li>Floorplans with measurements</li>
            <li>Unlimited Open Houses</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <homepage-footer></homepage-footer>
</main>
