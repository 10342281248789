import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {RouterLink} from "@angular/router";


@Component({
  selector: 'homepage-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    FaIconComponent,
    RouterLink,
  ]
})
export class FooterComponent {

  public currentYear = new Date().getFullYear();

	constructor() { }
}
