import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@app/icons/icons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowRight,
  faPhone,
  faKey,
  faHeadset,
  faMessage
} from '@fortawesome/free-solid-svg-icons';
import { FooterComponent } from '@components/footer/footer.component';

@Component({
  selector: 'app-rewards',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    IconsModule,
    RouterModule,
    FooterComponent
  ],
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  public readonly ARROW_RIGHT = faArrowRight;
  public readonly PHONE = faPhone;
  public readonly KEY = faKey;
  public readonly HEADSET = faHeadset;
  public readonly MESSAGE = faMessage;

  constructor() { }
}
