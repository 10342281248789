import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router'; // for routing and accessing the route (URL) params

import { AuthService } from "@services/auth.service";
import { UIComponentBase } from "@buildable/foundation";
import { AuthResult } from '../../models/auth-result';
import { AuthUser } from '../../models/auth-user';
import { LogNinja } from "@services/log-ninja.service";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";


@Component({
  selector: 'reset-password',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent extends UIComponentBase implements OnInit, OnDestroy {
  public loading = true;
  public validHash = false;
  public errorMessageHash = '';
  public errorMessage = '';
  public successMessage = '';
  public hash = '';
  public password1 = '';
  public password2 = '';
  public passwordsValid = false;
  public updating = false;
  public authResultTemp: AuthResult | null = null;
  public reset = true;

  public auth = inject(AuthService);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _logger = inject(LogNinja);

  constructor() {
    super();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.loading = true;
    this.authResultTemp = null;

    // logout without redirecting anywhere
    // to ensure that nobody resets a password while logged in
    this.auth.logout(true, false, '');

    // figure out if this is from a reset-password or a create-password route
    this.addSubscriptions(this._route.data.subscribe(data => {
      this.reset = data["reset"];
    }));

    this.addSubscriptions(this._route.params.subscribe(params => {
      this.hash = params['hash']; // the reset hash
      if (this.hash == '') {
        this.errorMessageHash = 'No key provided. Cannot proceed.';
        this.validHash = false;
        this.loading = false;
      } else {
        this.auth.isValidPasswordResetHash(this.hash).subscribe({
          next: (item) => {
            this.loading = false;
            if (item) {
              this.authResultTemp = item;
              this.errorMessageHash = '';
              this.validHash = true;
            } else {
              this.errorMessageHash = 'Invalid or expired key. Cannot proceed.';
              this.validHash = false;
            }
          },
          error: (err) => {
            // got an error
            this.errorMessageHash = err.statusText;
            // check error.status if custom processing needed
            this.validHash = false;
            this.loading = false;
          }
        });
      }
    }));
  }

  goToLogin() {
    this._router.navigate(['/login']);
  }

  validatePassword() {
    this.errorMessage = '';
    this.password1 = this.password1.trim();
    this.password2 = this.password2.trim();

    var re = new RegExp('^(?=.*[!@#$%\-_\*\.])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%\-_\*\.]{8,}$');
    if (!re.test(this.password1)) {
      this.errorMessage = 'Your new password must contain at least eight characters, including one lowercase and one uppercase letter, one number, and one special character (ex. ! @ # $ or %).';
      this.passwordsValid = false;
      return false;
    }
    if (this.password1 != this.password2) {
      this.errorMessage = 'Your new password and the password confirmation must match.';
      this.passwordsValid = false;
      return false;
    }

    this.passwordsValid = true;
    return true;
  }


  updatePassword() {
    if (!this.validatePassword()) {
      return;
    }
    this.errorMessage = '';
    this.successMessage = '';
    this.updating = true;
    let resetMode = this.reset ? AuthUser.CST_MODE_RESET : AuthUser.CST_MODE_CREATE;

    this.auth.updatePassword(this.hash, this.password1, resetMode).subscribe({
      next: () => {
        this.updating = false;
        this.successMessage = 'Success! Your password has been changed. You may now login with your new password.';
      },
      error: (error: any) => {
        this.updating = false;
        // check error.status if custom processing needed
        this.errorMessage = error.statusText;
        return null;
      },
      complete: () => {
        this._logger.log('update password finished');
      }
    });
  }
}
