
<footer class="footer">
  <div class="schedule py-9">
    <div class="container">
        <div class="d-flex flex-column align-items-center text-primary">
          <h5 class="h4-responsive font-weight-bolder text-center">Schedule your complimentary discovery call today!</h5>
          <a href="https://calendly.com/d/cmw5-djn-6n2/buyer-consultation" target="_blank" class="btn btn-tertiary btn-lg px-4 my-5 ">
            <fa-icon [icon]="['fas', 'phone']" class="mr-1"></fa-icon>
            Talk with an Agent
          </a>

          <p class="mb-1"><small>Or reach out a different way, any time:</small></p>
          <p class="mb-1"><small>Call/Text: <a href="tel:2066124055">206-612-4055</a></small></p>
          <p><small>Email: <a href="mailto:support@homewrite.com">support&#64;homewrite.com</a></small></p>

          <!-- partnerships ended, for the time being -->
          <!-- <div class="pt-4 text-center ">
            <p class="body-responsive text-secondary">Proudly partnering with</p>
            <div>
              <img src="/images/homepage/homepage_12.png" alt="ticor logo" class="partner-logo ticor">
              <img src="/images/homepage/homepage_13.png" alt="guild logo" class="partner-logo guild">
            </div>
          </div> -->
        </div>
    </div>
  </div>

	<div class="container py-6">
    <!-- Switch to row at sm; column on xs -->
    <div class="d-flex flex-column flex-sm-row align-items-center">
      <!-- Logo -->
      <div class="me-sm-4 mb-4 mb-sm-0 mr-0 mr-sm-4">
        <a [routerLink]="['/home']">
          <img
            src="/images/homewrite_logo_white.png"
            style="height: 56px; width: auto"
            title="Homewrite Platform"
          />
        </a>
      </div>

      <!-- Address -->
      <div class="me-sm-auto mb-4 mb-sm-0">
        <p class="mb-0">
          11335 NE 122nd Way, Suite 105 <br>
          Kirkland, WA 98034
        </p>
      </div>

      <span class="flex-grow-1"></span>

      <!-- Right-Side Links -->
      <ul
        class="list-unstyled d-flex flex-column flex-sm-row align-items-center align-items-sm-center mb-0"
      >
        <!--  public    -->
        <li class="nav-link px-0 me-sm-5 me-2 mr-0 mr-sm-4">
          <a [routerLink]="['/terms-and-conditions']" class="text-white">Terms of Use</a>
        </li>
        <!-- <li class="nav-link px-0 me-sm-5 me-2">
          <a href="#" class="text-white">Privacy Policy</a>
        </li> -->
        <li class="nav-link px-0 mt-4 mt-sm-0">
          © Homewrite.com {{ currentYear }}
        </li>
      </ul>
    </div>
  </div>

</footer>

