import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@components/footer/footer.component';

@Component({
  selector: 'app-listing-services',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FooterComponent
  ],
  templateUrl: './listing-services.component.html',
  styleUrls: ['./listing-services.component.scss']
})
export class ListingServicesComponent {

  constructor() { }
}
