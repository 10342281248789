<main class="about-us-page">
  <!--  -->
  <section data-aos="fade-up" class="brand-gradient-block py-6">
    <div class="container-sm">
      <div alt="homewrite logo" class="logo-bg"></div>

      <div class="row mb-4">
        <div class="col">
          <h1 class="h1-responsive font-weight-bolder">
            Learn More
          </h1>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="body-responsive mb-4 subtitle-text">
            <b>Homewrite.com</b> is an online real estate brokerage addressing the challenges of the modern home buyer:
          </p>

          <ul class="body-responsive">
            <li>Most agents require exclusive contracts</li>
            <li>Committing to one agent feels scary/risky</li>
            <li>Agents charge up to 3% of the cost of the home</li>
            <li>Buyers may have to negotiate their agent’s compensation</li>
            <li>Buyers are dependent on agent’s schedule</li>
            <li>Offer writing process is slow and confusing</li>
            <li>Buyers can find their own homes, lowering the agent’s value</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <!-- SOLUTION -->
  <section class="solution-section brand-white-block py-6" data-aos="fade-up">
    <div class="container-sm">

      <div class="row mb-5">
        <div class="col">
          <h2 class="h3-responsive font-weight-bolder mb-5">
            The solution is the search
          </h2>

          <p class="body-responsive mb-0" style="font-size: 22px; font-weight: 300;">
            By removing ourselves from the home search, Homewrite.com is able to provide three key benefits to our clients:
          </p>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            Representation
          </h4>

          <p class="body-responsive mb-0">
            We provide the guidance you need and deserve, without the restrictive terms or high cost of traditional agents.
          </p>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            No Exclusive Contracts
          </h4>

          <p class="body-responsive mb-0">
            We are simply here when you need us.
          </p>
        </div>
      </div>


      <div class="row mb-5">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            Money Back at Closing
          </h4>

          <p class="body-responsive mb-0">
            When the seller offers a commission, we take a small portion of it and give the rest back to you.
          </p>
        </div>
      </div>

    </div>
  </section>

  <!-- OFFER TO CLOSING BENEFITS -->

  <section class="offer-to-closing-section brand-gradient-block py-6" data-aos="fade-up">
    <div class="container-sm">
      <div class="row">
        <div class="col">
          <h2 class="h3-responsive font-weight-bolder mb-5">
            Offer to Closing Benefits
          </h2>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            Home by Home Basis
          </h4>

          <p class="body-responsive mb-0">
            We are here whenever you want to make an offer. No commitment unless offer is accepted.
          </p>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            We Write Your Offer in Minutes
          </h4>

          <p class="body-responsive mb-0">
            We provide a team of local licensed experienced Agents day and night, so that we are always available on your schedule.
          </p>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            We Write Your Offer in Minutes
          </h4>

          <p class="body-responsive mb-0">
            Just come to Homewrite.com. We will respond in 15 minutes or less, and can have your offer prepared immediately.
          </p>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            Our Buyers Keep Most of the Commission
          </h4>

          <p class="body-responsive mb-0">
            We simply take a low flat fee from the commission that a seller offers and empower our buyers to decide what to do with the rest.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- HOW DOES IT WORK? -->
  <section class="how-does-it-work-section brand-white-block py-6" data-aos="fade-up">
    <div class="container-sm">
      <div class="row">
        <div class="col">
          <h2 class="h3-responsive font-weight-bolder mb-4">
            How Does It Work?
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <ol class="body-responsive pl-5">
            <li>You search online & go to open houses</li>
            <li>You tell us the home you want via our “start offer” button</li>
            <li>We call you within 15 minutes to discuss</li>
            <li>If you say go, we write your offer & send for signatures</li>
            <li>We negotiate with the seller’s agent</li>
            <li>We notify you with their response</li>
            <li>We handle inspections & guide you to closing</li>
            <li>We meet you at the home on closing day with the keys</li>
          </ol>
        </div>
      </div>

      <table class="table table-borderless comparison-table">

        <tbody>
          <tr>
            <!-- Column headings -->
            <th class="text-center" style="font-weight: 700;">
              Homewrite.com
            </th>
            <th></th>
            <th class="text-center" style="font-weight: 300;">
              Traditional Brokerages
            </th>
          </tr>

          <!-- Row 1 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="XMARK" class="text-muted"></fa-icon>
            </td>
            <td class="text-center">Search-to-closing model</td>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
          </tr>

          <!-- Row 2 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
            <td class="text-center">Offer-to-closing model</td>
            <td class="text-center">
              <!-- If you want a partial or no check, just remove the icon or use a different style -->
              <fa-icon size="lg" [icon]="XMARK" class="text-muted"></fa-icon>
            </td>
          </tr>

          <!-- Row 3 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
            <td class="text-center">Agent Expertise</td>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
          </tr>

          <!-- Row 4 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
            <td class="text-center">24/7 Availability</td>
            <td class="text-center">
              <!-- Example: maybe a lighter check or none here -->
              <fa-icon size="lg" [icon]="XMARK" class="text-muted"></fa-icon>
            </td>
          </tr>

          <!-- Row 5 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
            <td class="text-center">Instant Offer writing</td>
            <td class="text-center">
              <fa-icon size="lg" [icon]="XMARK" class="text-muted"></fa-icon>
            </td>
          </tr>

          <!-- Row 6 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
            <td class="text-center">No Exclusive Contracts</td>
            <td class="text-center">
              <fa-icon size="lg" [icon]="XMARK" class="text-muted"></fa-icon>
            </td>
          </tr>

          <!-- Row 7 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
            <td class="text-center">Money back to buyer</td>
            <td class="text-center">
              <!-- Possibly blank or partial check if desired -->
              <fa-icon size="lg" [icon]="XMARK" class="text-muted"></fa-icon>
            </td>
          </tr>

          <!-- Row 8 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
            <td class="text-center">Closing Guidance</td>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
          </tr>

          <!-- Row 9 -->
          <tr>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
            <td class="text-center">Hand you the keys</td>
            <td class="text-center">
              <fa-icon size="lg" [icon]="['fas','check']" class="text-tertiary"></fa-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <!-- FAQ -->
  <section class="faq-section brand-gradient-block py-6" data-aos="fade-up">
    <div class="container-sm">
      <div class="row">
        <div class="col">
          <h2 class="h3-responsive font-weight-bolder mb-4 ml-4">
            FAQ
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <ul class="faq-list">
            <li>
              <strong>How does Homewrite save me money on my home purchase?</strong><br>
              We lower the cost of having a real estate agent by not going on the home search.
              When a seller offers a commission, we take a small flat fee and pass the rest to you.
            </li>

            <li>
              <strong>What documents do I need to upload or prepare for my offer submission?</strong><br>
              To make an offer you simply need to upload a pre-approval letter from a lender, or proof of funds.
              If you don’t have either, we can help connect you with a lender!
            </li>

            <li>
              <strong>Will I get help with understanding the legal terms in the offer?</strong><br>
              Yes! We will walk you through all of the paperwork, and explain the contract in detail.
              We will be with you from start to finish to answer questions and provide guidance.
            </li>

            <li>
              <strong>What if the seller counters my offer? How will I be notified, and what should I do next?</strong><br>
              If the seller counters your offer we will notify you via e-mail and phone call.
              We will provide our recommendations on how to respond and handle all paperwork/negotiations on your behalf.
            </li>

            <li>
              <strong>Can I submit offers on multiple homes at the same time?</strong><br>
              To protect our buyers, we only permit buyers to submit one offer at a time.
              We do this so you don’t contractually obligate yourself to buying multiple houses.
            </li>

            <li>
              <strong>How long does it usually take for my offer to be reviewed and sent to the seller?</strong><br>
              When you submit our “start offer” form we will contact you within 15 minutes.
              If you decide to make an offer we will have your offer ready to sign within 5 minutes,
              at which point you will receive an email to sign. Once you have signed we will
              immediately send to the seller’s agent to review.
            </li>

            <li>
              <strong>What should I expect after my offer is submitted?</strong><br>
              After your offer is submitted, you should expect to hear from your Homewrite agent with
              an update by end of day. They will keep you in the loop via your preferred method of
              communication as soon as they have news to report back.
            </li>

            <li>
              <strong>Does Homewrite assist with finding financing or pre-approval letters?</strong><br>
              Yes! Homewrite.com has two lender partners we can introduce you to.
            </li>

            <li>
              <strong>Can I use Homewrite if I’m buying a home out of state?</strong><br>
              Homewrite.com is currently only available in Washington. If there’s a state you
              are interested in purchasing, please e-mail us at <a href="mailto:support@homewrite.com">support&#64;homewrite.com</a> to let us know!
            </li>

            <li>
              <strong>What kind of support will I have if I run into issues with the platform?</strong><br>
              If you run into technical difficulties—just call 206-612-4055 and a Homewrite.com
              representative will guide you.
            </li>

            <li>
              <strong>What should I do if I need to adjust my offer after it’s been submitted?</strong><br>
              Once an offer has been submitted to the seller, it cannot be altered.
              The best way to avoid this is to review all terms & conditions carefully so that you are
              100% certain in the offer you are making.
            </li>

            <li>
              <strong>Is my personal and financial information secure on the platform?</strong><br>
              Yes. We take cyber security very seriously and have incorporated all industry standard tools
              and technologies into keeping your data secure. Learn more about how we are protecting
              your data in our privacy policy.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>


  <homepage-footer></homepage-footer>
</main>
