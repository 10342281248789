<main class="about-us-page">
  <!--  -->
  <section data-aos="fade-up" class="brand-gradient-block py-6">
    <div class="container-sm">
      <div class="row mb-4">
        <div class="col">
          <h1 class="h1-responsive font-weight-bolder">
            About Us
          </h1>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            An Online Real Estate Brokerage
          </h4>

          <p class="body-responsive mb-0 subtitle-text">
            Homewrite.com offers a team of licensed local agents serving Washington State.
          </p>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder">
            Built for Buyers
          </h4>

          <p class="body-responsive mb-0 subtitle-text">
            We work on your schedule, when you need us. It’s just that simple.
          </p>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <h4 class="h5-responsive font-weight-bolder text-white">
            Making Real Estate About You
          </h4>

          <p class="body-responsive mb-0 subtitle-text">
            Our platform is designed to serve your needs, not an agents.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- FOUNDER LETTER -->
  <section class="founder-letter-section brand-brown-block py-6" data-aos="fade-up">
    <div class="container-sm">
      <div alt="homewrite logo" class="logo-bg"></div>

      <div class="row">
        <div class="col">
          <h2 class="h3-responsive font-weight-bolder mb-4">
            A letter from our founder
          </h2>

          <p class="body-responsive">Dear Homebuyer,</p>
          <p class="body-responsive">At Homewrite.com, we know that buying a home is an expression of your individuality and independence. We believe the home buying journey should be too.</p>
          <p class="body-responsive">Gone are the days where home buyers were reliant on an agent to identify available homes. We now live in a world where all of the homes are at our finger tips. The true need lies in having an expert to guide you from making an offer all the way to closing.</p>
          <p class="body-responsive">Our platform is designed to do this. We provide the expertise and guidance you need without all the hoopla.</p>
          <p class="body-responsive">Revel in and embrace your ability to find your own home because while we know real estate, you know you.</p>
        </div>
      </div>

      <div class="row d-flex">
        <span class="flex-grow-1"></span>
        <div class="d-flex flex-column" >
          <!-- important to set filter here, otherwise it's all white -->
          <img src="/images/homewrite_founder_signature.png" alt="Founder Signature" width="130" style="filter: brightness(0)">
          <div>Nico Lang</div>
          <div>Founder, CEO</div>
          <div>Homewrite.com</div>
        </div>
      </div>
    </div>
  </section>

  <!-- FOOTER (partner-logos are presumably inside the footer now) -->
  <homepage-footer></homepage-footer>
</main>
