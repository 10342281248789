
<main class="homepage" style="overflow: hidden;">


  <section class="hero">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column my-6 py-6 px-4 px-md-0" data-aos="fade-up">
            <div>
              <h1 class="fw-normal hero-responsive mb-0">Buy your home<br> online, anytime</h1>
            </div>

            <div class="pt-4 pb-6">
              <a [routerLink]="['/register']" class="btn btn-tertiary btn-lg px-4">
                Start an Offer
                <fa-icon [icon]="['fas', 'arrow-right']" class="ml-1"></fa-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <div class="bg-white">
    <div class="container">
      <div class="row py-6" data-aos="fade-up">
        <div class="col-lg-4 d-flex justify-content-center mb-4 mb-lg-0">
          <img
            src="/images/homepage/homepage_6.png"
            alt="about-image"
            class="w-lg-100 brand-new-image image-cover home-phone-image">
        </div>
        <div class="col-lg-8 d-flex flex-column justify-content-center pl-lg-5">
          <h2 class="h3-responsive font-weight-bolder">A brand new way to buy real estate</h2>
          <div class="row">
            <p class="body-responsive col-10 col-lg-8 my-4">
              Homewrite.com provides buyers with offer writing & closing services once you’ve found the home you want.
              We write your offer at a moment’s notice, with no exclusive contracts.
            </p>
          </div>
          <div>
            <a href="https://calendly.com/d/cmw5-djn-6n2/buyer-consultation" target="_blank" class="btn btn-outline-primary btn-lg px-4">
              <fa-icon [icon]="['fas', 'phone']" class="mr-1"></fa-icon>
              Talk with an Agent
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="offer-to-closing brand-gradient-block">
    <div class="container">
      <div class="row py-6 text-white">
        <div class="col-12 text-center mb-5" data-aos="fade-down">
          <h2 class="h3-responsive text-white">Our <strong>Offer-to-Closing</strong> model:</h2>
        </div>
        <div class="col-lg-6 pr-lg-4 " data-aos="fade-right">
          <img
            src="/images/homepage/homepage_7.png"
            alt="about-image"
            class="w-100 about-image image-cover">
          <h3 class="h3-responsive text-white my-4 font-weight-bold">You search</h3>
          <p class="body-responsive">
            Look for homes online & visit open houses. <br>
            When you’ve found the home for you, start your offer at
            Homewrite.com
          </p>
        </div>
        <div class="col-lg-6 pl-lg-4" data-aos="fade-left">
          <img
            src="/images/homepage/homepage_8.png"
            alt="about-image"
            class="w-100 about-image image-cover">
          <h3 class="h3-responsive text-white my-4 font-weight-bold">We guide you</h3>
          <ul class="body-responsive homepage-ul">
            <li>Complimentary strategy call with us</li>
            <li>We write & negotiate your offer</li>
            <li>We handle inspections & closing guidance</li>
            <li>Available to write offers anytime (24/7)</li>
            <li>No exclusive contracts required</li>
            <li>Money back with Homewrite Rewards</li>
          </ul>
        </div>

        <div class="col-12 d-flex justify-content-center mt-5" data-aos="zoom-in">
          <a href="https://calendly.com/d/cmw5-djn-6n2/buyer-consultation" target="_blank" class="btn btn-secondary btn-lg px-4 btn-shadow">
            <fa-icon [icon]="['fas', 'phone']" class="mr-1"></fa-icon>
            Talk with an Agent
          </a>
        </div>
      </div>
    </div>
  </div>


  <div class="homewrite-offer bg-white">
    <div class="container">
      <div class="row py-4">
        <div class="col-lg-6 pr-lg-4" data-aos="fade-right">
          <p class="body-sm-responsive mb-5 text-black">
            What you do:
          </p>
          <div class="row">
            <img
              src="/images/homepage/homepage_laptop.svg"
              alt="laptop icon"
              class="col-4 laptop-icon">
            <h3 class="col-8 h3-responsive homewrite-offer-title mb-0">Find the home <br>you want</h3>
          </div>

          <a href="https://calendly.com/d/cmw5-djn-6n2/buyer-consultation" target="_blank" class="btn btn-tertiary btn-lg px-4 mt-5 mb-3">
            <fa-icon [icon]="['fas', 'phone']" class="mr-1"></fa-icon>
            Talk with an Agent
          </a>
          <p class="text-secondary"><small>(No commitment!)</small></p>

        </div>
        <div class="col-lg-6 pl-lg-4 mt-4 mt-lg-0" data-aos="fade-left">
          <p class="body-sm-responsive mb-lg-5 mb-2 text-black">
            What we do, virtually:
          </p>
          <div class="homewrite-way-container vertical mt-lg-5 d-flex">

            <div class="steps">
              <div class="step ">
                <fa-icon [icon]="['fas', 'phone']" class="icon"/>

                <p>Call you to strategize</p>
              </div>
              <div class="step">
                <div>
                  <img src="/images/homepage/homepage_9.png" alt="logo-icon" class="logo-icon">
                </div>
                <p>Write your offer</p>
              </div>
              <div class="step">
                <fa-icon [icon]="['fas', 'message']" class="icon"/>

                <p>Negotiate Deal</p>
              </div>
              <div class="step">
                <fa-icon [icon]="['fas', 'headset']" class="icon"/>
                <p>Guide you to closing</p>
              </div>
              <div class="step last-child">
                <fa-icon [icon]="['fas', 'key']" class="icon"/>

                <p>Give you Keys</p>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="we-are-here brand-gradient-block">
    <div class="container" >
      <div>
        <h3 class="h3-responsive font-weight-bold text-white mb-3">Try us risk free</h3>
        <ul class="body-responsive homepage-ul text-white mb-5">
          <li>Unlimited offer writing, totally free</li>
          <li>Home-by-home basis (no exclusive contracts)</li>
          <li>No commitment until your offer is accepted</li>
        </ul>
        <a [routerLink]="['/learn-more']" class="btn btn-secondary btn-shadow   px-4">
          Learn more
          <fa-icon [icon]="['fas', 'arrow-right']" class="ml-1"></fa-icon>
        </a>
      </div>

      <!--  Background image    -->
      <div class="logo-bg"></div>
    </div>
  </div>


  <div class="bg-white py-9">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 d-flex justify-content-center mb-4">
          <img
            src="/images/rewards/homewrite_rewards_logo.png"
            alt="Homewrite Rewards"
            class="offer-image"
          >
        </div>
        <div class="col-lg-8">
          <h3 class="h3-responsive font-weight-bolder">Your search, your money</h3>
          <p class="body-responsive">
            By untethering ourselves from the home search, we untether our buyers from the high cost of traditional real
            estate agents. Instead of keeping the entire commission (up to 3%) for ourselves, we take a small portion
            and give the rest back to you.
          </p>
          <a [routerLink]="['/rewards']" class="btn btn-primary btn-lg px-4 mt-4 mb-3">
            Explore Homewrite Rewards
            <fa-icon [icon]="['fas', 'arrow-right']" class="ml-1"></fa-icon>
          </a>

        </div>
      </div>
    </div>
  </div>

  <div class="mission brand-gradient-block">
    <div class="container text-center">
      <h5 class="h4-responsive text-white mb-5">
        Less about agents. More about <strong>you</strong>.
      </h5>
      <a [routerLink]="['/about-us']" class="btn btn-outline-light px-4">
        Our Mission
        <fa-icon [icon]="['fas', 'arrow-right']" class="ml-1"></fa-icon>
      </a>

      <!--  Background image    -->
      <div class="logo-bg"></div>
    </div>
  </div>

  <homepage-footer/>
</main>


